<template>
  <div id="app">
    <md-app md-mode="fixed">
      <md-app-toolbar class="md-primary md-large md-dense">
        <div class="md-toolbar-row md-title">Haggy's Jamulus Experiments</div>
        <div class="md-toolbar-row">
          <md-tabs class="md-primary" md-sync-route>
            <md-tab id="tab-fellows" md-label="Fellows" to="/users/fellows" exact></md-tab>
            <md-tab id="tab-users" md-label="Musicians" to="/users" exact></md-tab>
            <!--
            <md-tab id="tab-recordings" md-label="Recordings" to="/recordings" exact></md-tab>
            <md-tab id="tab-sessions" md-label="Sessions" to="/sessions" exact></md-tab>
            -->
          </md-tabs>
        </div>
      </md-app-toolbar>

      <md-app-content>
        <router-view></router-view>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import Vue from 'vue'
import VueMaterial from 'vue-material'
import VueRouter from 'vue-router'

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'md-icons/material-icons.css'

import Users from './components/Users.vue'
import Recordings from './components/Recordings.vue'
import Sessions from './components/Sessions.vue'

Vue.prototype.$API_HOST = 'http://localhost:5000'
Vue.prototype.$API_HOST = 'https://jamulus.haggy.org'

Vue.use(VueRouter)
Vue.use(VueMaterial)

const router = new VueRouter({
  routes: [
    { path: '/users/:mode', component: Users },
    { path: '/users', component: Users },
    { path: '/recordings', component: Recordings },
    { path: '/sessions', component: Sessions },
  ]
})

export default {
  router,
  name: 'App'
}
</script>

<style>
</style>
