<template>
  <div>
    <p class="md-subheading intro">This server supports automatic recording of your 
      jam sessions for use in <a href="https://www.reaper.fm">Reaper</a> or 
      <a href="https://www.audacityteam.org/">Audacity</a>. 
      Recordings are archived and kept for {{ expire_days }} days until they expire.</p>

    <md-table v-model="recordings" md-sort="filename" md-sort-order="asc">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="File" md-sort-by="filename">
          <md-button :href="`${$API_HOST}/recordings/${item.filename}`">
            {{ item.filename }}
          </md-button>
          <div class="musicians">
            <md-chip v-for="(info, name) in item.users" :key="name+info.ip">
              {{ name != '____' ? name : info.ip.replace(/_/g, '.') + '.x' }}
            </md-chip>
          </div>
        </md-table-cell>
        <md-table-cell md-label="Date" md-sort-by="mtime">{{ item.mtime | localDate }}</md-table-cell>
        <md-table-cell md-label="Expires" md-sort-by="expires">{{ expireDate(item.mtime) }}</md-table-cell>
        <md-table-cell md-label="Size" md-sort-by="size" md-numeric>{{ item.size | prettyBytes }}</md-table-cell>
      </md-table-row>
      <md-empty-state
        md-icon="mic"
        md-label="No recordings available">
      </md-empty-state>
    </md-table>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { DateTime, Settings } from "luxon"

Settings.defaultLocale = 'en'

Vue.filter('localDate', function(dateStr) {
  return DateTime.fromISO(dateStr).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
});

Vue.filter('prettyBytes', function (num) {
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
});

export default {
  name: 'Recordings',
  data() {
    return {
      recordings: [],
      expire_days: 5
    }
  },
  methods: {
    expireDate: function(mtime) {
      return DateTime.fromISO(mtime, { zone: 'utc' })
                     .plus({ days: this.expire_days })
                     .endOf('hour').toRelative()
    }
  },
  mounted () {
    axios
      .get(this.$API_HOST + '/api/recordings')
      .then(response => (this.recordings = response.data.recordings))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .musicians {
    max-width: fit-content;
    padding-left: 1em;
  }

  .musicians .md-chip {
    font-size: x-small;
    height: 22px;
    line-height: 22px;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    background-color: #e1f5fe;
  }

  .intro {
    text-align: center
  }
</style>
