<template>
  <div>
    <p class="md-subheading intro">This server keeps track of all active musicians
      on Jamulus. Search for your favorite fellows and follow them to
      see where they're currently jamming!</p>

    <h2 class="md-headline" v-if="mode == 'fellows'">Fellows online</h2>
    <h2 class="md-headline" v-else>Musicians online</h2>
    <md-table v-model="searched" md-sort="lastseen" md-sort-order="desc" class="users">
      <md-table-toolbar>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search by username..." v-model="term" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>

      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="{ online: isOnline(item) }">
        <md-table-cell md-label="Username" md-sort-by="name">
          <md-button class="md-icon-button" @click="onFollowUser(item)">
            <md-icon v-if="followsUser(item)">
              <md-tooltip md-direction="top">Remove this musician from your list</md-tooltip>
              remove
            </md-icon>
            <md-icon v-else>
              <md-tooltip md-direction="top">Add this musician to your list</md-tooltip>
              add
            </md-icon>
          </md-button>
          {{ item.name }}
        </md-table-cell>
        <md-table-cell md-label="Instrument" md-sort-by="instrument">{{ item.instrument }}</md-table-cell>
        <md-table-cell md-label="Server" md-sort-by="server">{{ item.server }}</md-table-cell>
        <md-table-cell md-label="Genre" md-sort-by="genre">{{ item.genre }}</md-table-cell>
        <md-table-cell md-label="Last seen" md-sort-by="lastseen">{{ relativeTime(item.lastseen) }}</md-table-cell>
      </md-table-row>
      <md-empty-state
        md-icon="group"
        md-label="No musicians">
      </md-empty-state>
    </md-table>
    <md-snackbar md-position="center" :md-duration="2000" :md-active.sync="showSnackbar">
      <span>{{ snackBarText }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import axios from 'axios'
import { DateTime } from "luxon"

const UPDATE_INTERVAL_SECS = 300

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => String(item.name).toLowerCase().includes(term.toLowerCase()))
  }

  return items
}

const selectNames = (items, names) => {
  return items.filter(item => names.indexOf(String(item.name)) > -1)
}

const selectRecent = (items, num) => {
  return items.sort((a, b) => new Date(b.lastseen) - new Date(a.lastseen)).slice(0, num)
}

export default {
  name: 'Users',
  data() {
    return {
      term: null,
      searched: [],
      users: [],
      followed: [],
      mode: '',
      snackBarText: '',
      showSnackbar: false
    }
  },
  methods: {
    relativeTime(lastseen) {
      return DateTime.fromISO(lastseen, { zone: 'utc' }).toRelative()
    },
    searchOnTable() {
      if (this.term.length > 2) {
        this.searched = searchByName(this.users, this.term)
      } else if (this.mode == 'fellows') {
        this.showFellows()
      } else {
        this.searched = selectRecent(this.users, 50)
      }
    },
    onFollowUser(user) {
      let user_id = user.name
      let idx = this.followed.indexOf(user_id)
      if (idx > -1) {
        axios
          .delete(this.$API_HOST + '/api/fellows/' + user_id)
          .then(response => {
            this.followed = response.data.fellows
            this.showFellows()
            this.snackBarText = `You removed ${user.name}`
            this.showSnackbar = true
          })
      } else {
        axios
          .post(this.$API_HOST + '/api/fellows', {'fellow': user_id})
          .then(response => {
            this.followed = response.data.fellows
            this.showFellows()
            this.snackBarText = `You added ${user.name}`
            this.showSnackbar = true
          })
      }
    },
    showFellows() {
      this.searched = this.sortByLastseen(selectNames(this.users, this.followed))
    },
    followsUser(user) {
      return this.followed.indexOf(user.name) > -1
    },
    sortByLastseen(users) {
      return users.sort((a, b) => new Date(b.lastseen) - new Date(a.lastseen))
    },
    isOnline(user) {
      const ls = DateTime.fromISO(user.lastseen, { zone: 'utc' })
      const diff = DateTime.utc().diff(ls, 'seconds').toObject()
      return diff.seconds <= UPDATE_INTERVAL_SECS + 60
    },
    loadUsers() {
      this.snackBarText = 'Loading, please wait...',
      this.showSnackbar = true
      axios
        .get(this.$API_HOST + '/api/users')
        .then(response => {
          this.showSnackbar = false
          this.users = response.data.users
          this.followed = response.data.fellows
          if (this.mode == 'fellows') {
            this.showFellows()
          } else {
            this.searched = selectRecent(this.users, 50)
          }
        })
    }
  },
  watch: {
    $route(to) {
      this.mode = to.params.mode || ''
      this.loadUsers()
    }
  },
  mounted() {
    this.mode = this.$route.params.mode || ''
    this.loadUsers()
    window.setInterval(this.loadUsers.bind(this), UPDATE_INTERVAL_SECS * 1000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button {
    vertical-align: middle;
  }

  .intro {
    text-align: center
  }

  .md-table-row {
    white-space: nowrap;
  }

  .md-table-row.online {
    font-weight: bold;
  }
</style>
